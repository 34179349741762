<template>
    <pagination :title="title" :entities="users.entities" :filterAction="filterAction" :hasEntities="users.entities.length > 0" headerColourClass="bg-transparent">
        <template v-slot:title>
            <div class="flex justify-between items-center">
                <span>{{title}}</span>
                <div class="mr-2">
                    <div class="flex items-center">
                        <button @click="getPrevDate()" type="button" class="flex bg-v3-gray-50 bg-opacity-10 rounded-md mr-1 p-1 text-v3-gray-600 dark:text-v3-gray-400 ml-2">
                            <span class="sr-only">Previous month</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <DatePicker v-model="date" :highlighted="highlighted" :max-date="new Date()" @input="changeDay" :class="{'rounded-r-md border-r' : isCurrentPageToday()}" class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-0"
                                    input-class="p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 dark:bg-white dark:text-white dark:text-opacity-70 dark:bg-opacity-5 placeholder-v3-gray-900 placeholder-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 dark:placeholder-op dark: form-input focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md border-0"
                                    calendar-class="dark:bg-secondary-appgray dark:text-v3-gray-200">
                            <template #default="{ togglePopover }" >
                                <button type="button" @click="togglePopover" class="h-full w-full px-3.5" >
                                    {{getCurrentPageName()}}
                                </button>
                            </template>
                        </DatePicker>
                        <button v-if="!isCurrentPageToday()" @click="getNextDate()" type="button" class="flex bg-v3-gray-50 bg-opacity-10 rounded-md p-1 text-v3-gray-600 dark:text-v3-gray-400 ml-1 mr-2">
                            <span class="sr-only">Next month</span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <template v-if="title === 'Attendance'">
                        <a v-if="hasLink(links, 'oss-daily-report')" href="/hr-attendance" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/hr-attendance'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/hr-attendance')}">Daily Report </a>
                        <a v-if="hasLink(links, 'oss-weekly-report')" href="/hr-weekly" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/hr-weekly'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/hr-weekly')}">Weekly Report </a>
<!--                    <a v-if="hasLink(links, 'oss-daily-report')" href="/hr-custom" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/hr-custom'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/hr-custom')}">Daily Report </a>-->
                    </template>
                </div>
            </div>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">In</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Out</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Comment</th>
        </template>
        <template v-slot:items>
            <tr v-for="user in users.entities">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{user.entity.properties.get('user')}}<br>
                    <span class="text-v3-gray-600">{{user.entity.properties.get('email')}}</span>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <div class="flex align-items-center">
                        <span class="flex">{{formatToTime(user.entity.properties.get('time_in'))}}</span>
                        <span class="flex" v-if="user.entity.properties.get('location_in')">
                            <span class="px-1">/</span>
                            <location-viewer-with-icon :x="user.entity.properties.get('location_in')['x']" :y="user.entity.properties.get('location_in')['y']"></location-viewer-with-icon>
                            {{user.entity.properties.get('location_in')['project']}}
                        </span>
                        <span class="flex" v-if="user.entity.properties.get('photo_in')">
                            <span class="px-1">/</span>
                            <photo-viewer-with-icon :file-name="user.entity.properties.get('photo_in')"></photo-viewer-with-icon>
                        </span>
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <span class="flex align-items-center" v-if="user.entity.properties.get('time_out') !== 'N/A'">
                        <span class="flex">{{formatToTime(user.entity.properties.get('time_out'))}}</span>
                        <span class="flex" v-if="user.entity.properties.get('location_out')">
                            <span class="px-1">/</span>
                            <location-viewer-with-icon :x="user.entity.properties.get('location_out')['x']" :y="user.entity.properties.get('location_in')['y']"></location-viewer-with-icon>
                            {{user.entity.properties.get('location_out')['project']}}
                        </span>
                        <span class="flex" v-if="user.entity.properties.get('photo_out')">
                            <span class="px-1">/</span>
                            <photo-viewer-with-icon :file-name="user.entity.properties.get('photo_out')"></photo-viewer-with-icon>
                        </span>
                    </span>
                    <span v-else>{{user.entity.properties.get('time_out')}}</span>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('comment_in')}}<br>
                    {{user.entity.properties.get('comment_out')}}
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import dayjs from "dayjs";
import LocationViewerWithIcon from "@/components/LocationViewerWithIcon.vue";
import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import moment from "moment";
import DatePicker from "vuejs-datepicker";

export default {
  name: 'HrPeoplePagination',
    components: {
        PropertyOrField,
        CustomButton,
        PhotoViewerWithIcon,
        LocationViewerWithIcon,
        Pagination,
        DatePicker
    },
    data(){
      return {
        date: this.getCurrentDate()
      }
    },
  props: {
    users: {},
      links: {
        type: Object,
          default: null
      },
      title:{
        type: String,
          default: 'Attendance'
      }
  },
    computed:{
        filterAction(){
            if(!this.users){
                return null;
            }
            return this.users.actions.filter(action => action.name === 'show-oss-daily-report').first();
        },
        highlighted:function() {
            return {
                dates: [ // Highlight an array of dates
                    new Date(),
                ]
            }
        }
    },
    methods:{
        formatToTime(datetime){
            return dayjs(datetime).format('HH:mm');
        },
        hasLink(links,key){
            if(links) {
                return links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        isActive(key){
            return this.$route.path.includes(key)
        },
        getPrevDate(){
            let prevDate = this.getCurrentDate();
            prevDate.setDate(prevDate.getDate() - 1);
            let showDeactivatedUsersQuery = '';
            if(this.$route.query['show_deactivated_users']){
                showDeactivatedUsersQuery = '&show_deactivated_users='+ this.$route.query['show_deactivated_users'];
            }
            let params = '';
            if(this.$route.query['organisation_id']){
                if(params !== ''){
                    params += '&';
                }
                params += 'organisation_id=' +  this.$route.query['organisation_id'];
            }
            if(this.$route.query['user_id']){
                if(params !== ''){
                    params += '&';
                }
                params += 'user_id=' + this.$route.query['user_id'];
            }
            if(this.$route.query['user_group']){
                if(params !== ''){
                    params += '&';
                }
                params += 'user_group=' + this.$route.query['user_group'];
            }
            if(showDeactivatedUsersQuery){
                params += showDeactivatedUsersQuery;
            }
            if(this.$route.query['project_id']){
                if(params !== ''){
                    params += '&';
                }
                params += 'project_id=' + this.$route.query['project_id'];
            }
            if(params){
                params = '&' + params;
            }
            let url = this.$route.path + '?date=' + moment(prevDate).format('YYYY-MM-DD') + params;
            this.$emit('setDate', moment(prevDate).format('YYYY-MM-DD'));
            window.location.href= url;
        },
        getNextDate(){
            let nextDate = this.getCurrentDate();
            nextDate.setDate(nextDate.getDate() + 1);
            if(nextDate > new Date()){
                return;
            }
            let showDeactivatedUsersQuery = '';
            if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                showDeactivatedUsersQuery = 'show_deactivated_users='+ this.$route.query['show_deactivated_users'];
            }
            let params = '';
            if(this.$route.query['organisation_id']){
                params +='organisation_id=' + this.$route.query['organisation_id'] + '&';
            }
            if(this.$route.query['user_id']){
                params +='user_id=' + this.$route.query['user_id'] + '&';
            }
            if(this.$route.query['project_id']){
                params +='project_id=' + this.$route.query['project_id'] + '&';
            }

            if(this.$route.query['user_group']){
                params += 'user_group=' + this.$route.query['user_group'] + '&';
            }
            if(showDeactivatedUsersQuery){
                params += showDeactivatedUsersQuery;
            }
            this.$emit('setDate', moment(nextDate).format('YYYY-MM-DD'));
            window.location.href=(this.$route.path + '?date=' + moment(nextDate).format('YYYY-MM-DD') +'&' + params);
        },
        goToToday(){
            let today = new Date();
            this.date = today;
            let showDeactivatedUsersQuery = '';
            if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                showDeactivatedUsersQuery = 'show_deactivated_users='+ this.$route.query['show_deactivated_users'];
            }
            let params = '';
            if(this.$route.query['organisation_id']){
                params +='organisation_id=' + this.$route.query['organisation_id'] + '&';
            }
            if(this.$route.query['user_id']){
                params +='user_id=' + this.$route.query['user_id'] + '&';
            }
            if(this.$route.query['project_id']){
                params +='project_id=' + this.$route.query['project_id'] + '&';
            }

            if(this.$route.query['user_group']){
                params += 'user_group=' + this.$route.query['user_group'] + '&';
            }
            if(showDeactivatedUsersQuery){
                params += showDeactivatedUsersQuery;
            }
            this.$emit('setDate', moment(today).format('YYYY-MM-DD'));
            window.location.href= (this.$route.path + '?date=' + moment(today).format('YYYY-MM-DD') + '&' + params);
        },
        isCurrentPageToday(){
            let nextDate = this.getCurrentDate();
            nextDate.setDate(nextDate.getDate() + 1);
            if(nextDate > new Date()){
                return true;
            }
            return false;
        },
        currentDate(){
            let currentDate = this.getCurrentDate();
            return moment(currentDate).format('D MMMM YYYY');
        },
        currentDay(){
            let currentDate = this.getCurrentDate();
            return moment(currentDate).format('dddd');
        },
        getCurrentPageName(){
            let currentDate = this.getCurrentDate();
            if(this.isCurrentPageToday()){
                return 'Today';
            }
            return moment(currentDate).format('Do MMM YYYY');
        },
        getCurrentDate(){
            let date = null;
            if(this.$route.query.date){
                date = this.$route.query.date;
            }
            let currentDate = new Date(date);
            if(this.$route.query.date === undefined || this.$route.query.date === 'null' || this.$route.query.date === null) {
                date = null;
                currentDate = new Date();
            }
            return currentDate;
        },
        changeDay(date){
            let showDeactivatedUsersQuery = '';
            if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                showDeactivatedUsersQuery = 'show_deactivated_users='+ this.$route.query['show_deactivated_users'];
            }
            let params = '';
            if(this.$route.query['organisation_id']){
                params +='organisation_id=' + this.$route.query['organisation_id'] + '&';
            }
            if(this.$route.query['user_id']){
                params +='user_id=' + this.$route.query['user_id'] + '&';
            }
            if(this.$route.query['project_id']){
                params +='project_id=' + this.$route.query['project_id'] + '&';
            }
            if(this.$route.query['user_group']){
                params += 'user_group=' + this.$route.query['user_group'] + '&';
            }
            if(showDeactivatedUsersQuery){
                params += showDeactivatedUsersQuery;
            }
            this.$emit('setDate', moment(date).format('YYYY-MM-DD'));
            window.location.href=(this.$route.path + '?date=' + moment(date).format('YYYY-MM-DD') + '&' + params);
        }
    }
}
</script>
